@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
    background: rgb(255, 255, 255) !important;
    color: #253237 !important;
    font-family: 'Montserrat', sans-serif !important;
}

p {
    letter-spacing: 1px;
}

/* All */
.fww {
    font-weight: 800 !important;
    line-height: 65px !important;
}


::-moz-selection {
    /* Code for Firefox */
    color: white;
    background: #FF4848;
}

::selection {
    color: white;
    background: #FF4848;
}

.shadow-custom {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3) !important;
    transition: 200ms;
}

.shadow-custom:hover {
    box-shadow: 2px 5px 15px -3px rgba(0, 0, 0, 0.2) !important;
    transition: 200ms;
}

.main {
    grid-template-columns: 20% 80%;
}

.sideBar {
    padding: 20px;
}

.sideBar_cat, .sideBar_price, .sideBar_view {
    padding: 12px 2px !important;
    border-bottom: 1px solid rgb(228, 228, 228);
}

.sideBar_cat_li {
    font-weight: 500;
}

.sideBar_cat_i {
    font-size: 10px !important;
}

@media screen and (max-width: 1150px) {
    .fww {
        line-height: 50px !important;
    }
}